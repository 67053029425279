import React, { Component } from "react";
import "primereact/resources/themes/saga-blue/theme.css";
import "primereact/resources/primereact.min.css";
import "primeicons/primeicons.css";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { Dialog } from "primereact/dialog";
import { Dropdown } from "primereact/dropdown";
import UserService from "../../services/UserService";
import CountryService from "../../services/CountryService";
import CompanyMasterService from "../../services/CompanyMasterService";
import { Button } from "primereact/button";
import { InputText } from "primereact/inputtext";
import tableRequest from "../../models/tableRequest.js";
import { confirmAlert } from "react-confirm-alert"; // Import
import { ConfirmationModal } from "../../template/ConfirmationModal";
import MessageComponent from "../../template/Message";
import $, { contains } from "jquery";
import ApiService from "../../services/apiService";
import Loaders from "../../template/Loaders";
import { BreadCrumb } from "primereact/breadcrumb";
import { Card } from "primereact/card";
import { CSVLink } from "react-csv";
// Manage Dealer data
export default class SalePersonMaster extends Component {
  constructor(props) {
    super(props);
    this.ApiService = new ApiService();
    this.UserService = new UserService();
    this.CompanyMasterService = new CompanyMasterService();
    this.CountryService = new CountryService();
    this.tblResponse = new tableRequest();
    this.tblResponseExport = new tableRequest();
    this.state = {
      heads: [
        "CompanyName",
        "FirstName",
        "EmpId",
        "Email",
        "MobileNo",
        "CreatedDt",
      ],
      exportData: [],
      visible: false,
      ReferenceID: "",
      updateLoader: false,
      sortIcon: false,
      UserName: "",
      ship_City: "",
      ship_State: "",
      ship_Country: "",
      ship_Address1: "",
      ship_Address2: "",
      ship_Pincode: "",
      chkbx: true,
      bill_City: "",
      bill_State: "",
      bill_Country: "",
      bill_Address1: "",
      bill_Address2: "",
      bill_Pincode: "",
      EmpId:"",
      FirstName: "",
      LastName: "",
      Email: "",
      MobileNo: "",
      Password: "",
      ConfirmPassword: "",
      GST: "",
      TIN: "",
      IsActive: false,
      sortOrder: "1",
      UserType: 6, //dealer
      CompanyId: "",
      companyDDL: [],
      formError: {
        ship_Country: "",
        UserName: "",
        ship_City: "",
        ship_State: "",
        bill_City: "",
        bill_State: "",
        FirstName: "",
        LastName: "",
        Password: "",
        bill_Country: "",
        Email: "",
        ConfirmPassword: "",
        GST: "",
        TIN: "",
        MobileNo: "",
        bill_Pincode: "",
        bill_Address1: "",
        bill_Address2: "",
        ship_Pincode: "",
        ship_Address1: "",
        ship_Address2: "",
        CompanyId: "",
      },

      formHeading: "Add Salesperson",
      buttonLabel: "Submit",
      sales: [],
      ship_citySelectItems: [],
      ship_stateSelectItems: [],
      ship_countrySelectItems: [],
      bill_citySelectItems: [],
      bill_stateSelectItems: [],
      bill_countrySelectItems: [],
      loading: true,
      first: 0,
      rows: 5,
      totalRecords: 0,
      globalFilter: null,
      emptyMessage: "No Data found",
      compDisabled: false,
    };

    this.actionTemplate = this.actionTemplate.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.SameAsBilling = this.SameAsBilling.bind(this);
    this.submitForm = this.submitForm.bind(this);
    this.hideFunc = this.hideFunc.bind(this);
    this.onPage = this.onPage.bind(this);
    this.searchFilter = this.searchFilter.bind(this);
    this.openDialog = this.openDialog.bind(this);
    this.onSort = this.onSort.bind(this);
    this.GetUserExport = this.GetUserExport.bind(this);
  }
  //trigger api function on page call
  componentDidMount() {
    setTimeout(() => {
      this.tblResponse.UserType = 6;
      this.GetUsers(this.tblResponse);
      this.GetStates(0);
      this.GetCities(0);

      this.GetCompany();
    }, 1000);

    $(".p-column-title").on("click", function () {
      $(this)
        .next()
        .removeClass("pi-sort")
        .toggleClass("pi-sort-up")
        .addClass("pi-sort-down");
    });

    this.GetCountry();

    this.tblResponseExport.PageSize = -1;
    this.tblResponseExport.First = 1;
    this.tblResponseExport.UserType = 6;
    this.tblResponseExport.IsActive = true;
    this.tblResponseExport.SearchValue = "";
    this.tblResponseExport.SortOrder = "ASC";
    this.GetUserExport();
  }

  // get user data list from api
  GetUserExport() {
    this.tblResponseExport.SortColumn = "CreatedDt";
    this.UserService.GetUsers(this.tblResponseExport).then((data) => {
      const resdata = data.data;
      //when response data is not null
      if (
        resdata !== null &&
        resdata.ResponseData !== null &&
        resdata.ResponseData.length > 0
      ) {
        this.setState({
          exportData: resdata.ResponseData,
        });
      }
    });
  }

  //loading page
  onPage(event) {
    this.setState({
      loading: true,
    });

    //imitate delay of a backend call
    setTimeout(() => {
      this.getLazyLoadingValues(event);
    }, 250);
  }

  // get list data
  getLazyLoadingValues(event) {
    this.setState({ rows: event.rows });
    this.tblResponse.PageSize = event.rows;
    this.tblResponse.First = event.first + 1;
    this.tblResponse.IsActive = true;
    this.tblResponse.SearchValue = event.globalFilter ? event.globalFilter : "";
    //this.tblResponse.SortColumn = event.sortField;
    this.tblResponse.SortOrder = event.sortOrder === 1 ? "ASC" : "DESC";
    this.tblResponse.UserType = 6;

    this.GetUsers(this.tblResponse);
  }

  //to get country data
  GetCountry() {
    this.CountryService.GetCountries("101").then((data) => {
      const resdata = data.data;
      if (
        resdata !== null &&
        resdata.ResponseData !== null &&
        resdata.ResponseData.length > 0
      ) {
        this.state.ship_countrySelectItems = resdata.ResponseData;
        this.state.bill_countrySelectItems = resdata.ResponseData;
      }
    });
  }

  SetState(cCode, fieldName) {
    if (fieldName == "bill_Country") {
      const resdata = this.state.allstate.filter((x) => x.CountryId == cCode);
      this.setState({
        bill_stateSelectItems: resdata,
      });
    }
    if (fieldName == "ship_Country") {
      const resdata = this.state.allstate.filter((x) => x.CountryId == cCode);
      this.setState({
        ship_stateSelectItems: resdata,
      });
    }
  }
  SetCity(sCode, fieldName) {
    if (fieldName == "bill_State") {
      const resdata = this.state.allcity.filter((x) => x.StateId == sCode);
      this.setState({
        bill_citySelectItems: resdata,
      });
    }
    if (fieldName == "ship_State") {
      const resdata = this.state.allcity.filter((x) => x.StateId == sCode);
      this.setState({
        ship_citySelectItems: resdata,
      });
    }
  }

  GetStates(cCode) {
    this.CountryService.GetStates(cCode).then((data) => {
      const resdata = data.data;

      this.setState({
        allstate: resdata.ResponseData,
        bill_stateSelectItems: resdata.ResponseData,
        ship_stateSelectItems: resdata.ResponseData,
      });

      //    if(fieldName == 'bill_Country')
      //    this.setState({
      //         "bill_stateSelectItems" : resdata.ResponseData,
      //         "ship_stateSelectItems" : resdata.ResponseData
      //     });

      //   if(fieldName == 'ship_Country')
      // this.setState({
      //      "ship_stateSelectItems" : resdata.ResponseData
      //  });
    });
  }

  GetCities(sCode) {
    this.CountryService.GetCities(sCode).then((data) => {
      const resdata = data.data;
      this.setState({
        allcity: resdata.ResponseData,
        bill_citySelectItems: resdata.ResponseData,
        ship_citySelectItems: resdata.ResponseData,
      });
      // if(fieldName == 'bill_State')
      // this.setState({
      //      "bill_citySelectItems" : resdata.ResponseData,
      //      "ship_citySelectItems" : resdata.ResponseData
      //  });

      // if(fieldName == 'ship_State')
      //  this.setState({
      //       "ship_citySelectItems" : resdata.ResponseData
      //   });
    });
  }

  // get users data list from api
  GetUsers(tbl) {
    this.tblResponse.SortColumn = "CreatedDt";
    this.UserService.GetUsers(tbl).then((data) => {
      const resdata = data.data;
      //when response data is not null
      if (
        resdata !== null &&
        resdata.ResponseData !== null &&
        resdata.ResponseData.length > 0
      ) {
        this.datasource = resdata.ResponseData;
        this.setState({
          totalRecords: resdata.ResponseData[0].TotalRows,
          first: this.tblResponse.First - 1,
          sales: this.datasource.slice(
            0,
            this.tblResponse.First - 1 + this.tblResponse.PageSize
          ),
          loading: false,
          updateLoader: true,
        });
      }
      //when response data is null
      else {
        this.datasource = null;
        this.setState({
          totalRecords: 0,
          first: 1,
          sales: null,
          loading: false,
          updateLoader: true,
        });
      }
    });
  }

  // call every change of form and validation part also
  handleChange(event) {
    if (
      this.state.bill_Pincode != (null || undefined || "") &&
      this.state.bill_Address1 != (null || undefined || "") &&
      this.state.bill_Address2 != (null || undefined || "") &&
      this.state.ship_Pincode != (null || undefined || "") &&
      this.state.ship_Address1 != (null || undefined || "") &&
      this.state.ship_Address2 != (null || undefined || "")
    ) {
      this.setState({
        chkbx: false,
      });
    }
    const { name, value } = event.target;
    let errors = this.state.formError;

    const isValid = this.validateForm(value, name);
    this.setState({ errors, [name]: value });
    //this statement will set state value for IsActive
    if (name == "IsActive")
      this.setState({
        IsActive: event.target.checked,
      });
  }

  // call to update billing to shipping
  SameAsBilling(event) {
    if (event.target.checked) {
      this.setState({
        ship_countrySelectItems: this.state.bill_countrySelectItems,
        ship_stateSelectItems: this.state.bill_stateSelectItems,
        ship_citySelectItems: this.state.bill_citySelectItems,
        ship_Country: this.state.bill_Country,
        ship_State: this.state.bill_State,
        ship_City: this.state.bill_City,
        ship_Address1: this.state.bill_Address1,
        ship_Address2: this.state.bill_Address2,
        ship_Pincode: this.state.bill_Pincode,
      });
    }
  }

  // Validate form
  validateForm(value, name) {
    let errors = this.state.formError;
    let IsValid = true;

    //this switch case is used for each form field to validate
    switch (name) {
      // case 'UserName':
      //     if (value.length < 1) {
      //         IsValid = false;
      //         errors.UserName = "Please enter your username.";
      //     } else errors.UserName = ''
      //     break;
      // case 'ship_Country':
      //     if (value == undefined || value == "") {
      //         IsValid = false;
      //         errors.ship_Country = "Please select country.";
      //     } else {
      //         errors.ship_Country = '';
      //         this.SetState(value.CountryId, name);
      //     }

      //     break;
     
      case "FirstName":
        if (value.length < 1) {
          IsValid = false;
          errors.FirstName = "Please Enter Your First Name.";
        } else errors.FirstName = "";
        break;
      // case "GST": 
      // if (value.length < 1) {
      //   IsValid = false;
      //   errors.GST = "Please enter your GST No.";
      // } else errors.GST = "";
      // break;
      // case "TIN":
      //   if (value == undefined || value == "" || value.length < 1) {
      //     IsValid = false;
      //     errors.TIN = "Please enter TIN";
      //   }

      // break;
      // case 'LastName':
      //     if (value.length < 1) {
      //         IsValid = false;
      //         errors.LastName = "Please enter your last name.";
      //     }
      //     else if (value.length >= 1) {
      //         if (!value.match(/^[A-Za-z]+$/)) {
      //             IsValid = false;
      //             errors.LastName = "LastName should be alpha only."
      //         } else errors.LastName = ''
      //     }
      //     else errors.LastName = ''
      //     break;
      case 'Email':
          if (value.length < 1) {
              IsValid = false;
              errors.Email = "Please enter your email-id.";
          }
          else if (value.length >= 1) {
              if (!value.match(/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/)) {
                  IsValid = false;
                  errors.Email = "Please enter valid email-id.";
              } else errors.Email = ''
          } else errors.Email = ''
          break;
      case "MobileNo":
        if (value.length < 1) {
          IsValid = false;
          errors.MobileNo = "Please Enter Your Mobile No.";
        } else if (value.length >= 1) {
          if (!value.match(/^[6-9]\d{9}$/)) {
            IsValid = false;
            errors.MobileNo = "Please enter valid mobile no.";
          } else errors.MobileNo = "";
        } else errors.MobileNo = "";
        break;
      case "Password":
        if (value.length < 1) {
          IsValid = false;
          errors.Password = "Please Enter Password.";
        } else if (!value.match(/^(?=.*[a-z])(?=.*[A-Z])(?=.*\d).{4,10}$/)) {
          IsValid = false;
          errors.Password =
            "Password should be minimum 4 characters long, 1 uppercase & 1 lowercase character with 1 number.";
        } else if (
          value.length >= 1 &&
          this.state.ConfirmPassword.length >= 1
        ) {
          errors.Password = "";
          errors.ConfirmPassword = "";
          if (!(value === this.state.ConfirmPassword)) {
            IsValid = false;
            errors.Password = "Password Does Not Match";
          } else errors.Password = "";
        } else errors.Password = "";
        break;

      case "ConfirmPassword":
        if (value.length < 1) {
          IsValid = false;
          errors.ConfirmPassword = "Please Enter Confirm Password.";
        } else if (value.length >= 1 && this.state.Password.length >= 1) {
          errors.Password = "";
          errors.ConfirmPassword = "";
          if (!(value === this.state.Password)) {
            IsValid = false;
            errors.ConfirmPassword = "Confirm Password Does Not Match";
          } else errors.ConfirmPassword = "";
        } else errors.ConfirmPassword = "";
        break;

      case "IsActive":
        this.setState({
          IsActive: value,
        });
        break;
     
      case "CompanyId":
        if (value == undefined || value == "") {
          IsValid = false;
          errors.CompanyId = "Please select Company";
        } else {
          errors.CompanyId = "";
        }
        break;

      default:
        break;
    }

    return IsValid;
  }

  // reset form text value
  formReset() {
    this.setState({
      ReferenceID: "",
      UserName: "",
      FirstName: "",
      LastName: "",
      chkbx: true,
      Email: "",
      MobileNo: "",
      Password: "",
      EmpId:"",
      ConfirmPassword: "",
      GST: "",
      TIN: "",
      IsActive: true,
      ship_City: "",
      ship_State: "",
      ship_Country: "",
      ship_Address1: "",
      ship_Address2: "",
      ship_Pincode: "",
      bill_City: "",
      bill_State: "",
      CompanyId:"",
      bill_Country: "",
      bill_Address1: "",
      bill_Address2: "",
      bill_Pincode: "",
    });
    let errors = this.state.formError;
    Object.entries(errors).map(([key, value]) => {
      errors[key] = "";
    });
  }

  hideFunc() {
    this.setState({
      visible: false,
      buttonLabel: "Submit",
      formHeading: "Add Salesperson",
    });
    this.formReset();
  }
  // open Dialog
  openDialog() {
    this.setState({ visible: true });
    this.formReset();
  }
  // submit form and create or update user
  submitForm(event) {
    event.preventDefault();

    let isfrmvalid = true;
    let checkerror = this.state.formError;

    Object.entries(checkerror).map(([key, value]) => {
      const val = this.state[key];
      let valid = this.validateForm(val, key);
      if (!valid) {
        this.setState({ checkerror: isfrmvalid });
        isfrmvalid = false;
      }
    });

    if (isfrmvalid) {
      
      this.setState({ updateLoader: false });
    debugger
      const formData = {
        //  UserName: this.state.,
        FirstName: this.state.FirstName,
       
        LastName: this.state.LastName,
        EmpId:this.state.EmpId,
        Email: this.state.Email,
        MobileNo: this.state.MobileNo,
        Password: this.state.Password,
        ReferenceID: this.state.ReferenceID,
        userId: this.state.userId,
        IsActive: this.state.IsActive,
        UserType: this.state.UserType,
        
        CompanyId: this.state.CompanyId.CompId,
      };

      setTimeout(() => {
        this.UserService.CreateUpdateUser(formData).then((data) => {
          const resdata = data.data;
          if (resdata !== null && resdata.ResponseCode === "200") {
            this.refs.Messchild.showSuccess(resdata.ResponseMessage);
            this.setState({
              loading: true,
              visible: false,
              updateLoader: true,
            });
            this.formReset();
            this.GetUsers(this.tblResponse);
          } else if (resdata.ResponseCode === "400") {
            this.setState({
              updateLoader: true,
            });
            this.refs.Messchild.showWarn(resdata.ResponseMessage);
          } else {
            this.setState({
              updateLoader: true,
            });
            this.refs.Messchild.showError(resdata.ResponseMessage);
          }
        });
      }, 100);
    }
  }

  // delete user data
  // deleteUser(referenceID) {
  //   this.UserService.DeleteUser(referenceID).then((data) => {
  //     const resdata = data.data;
  //     if (resdata !== null && resdata.ResponseCode === "200") {
  //       this.GetUsers(this.tblResponse);
  //       this.refs.Messchild.showSuccess(resdata.ResponseMessage);
  //       const totlrecord = this.state.totalRecords - 1;
  //       const row = this.state.rows;

  //       if (this.tblResponse.First >= totlrecord) {
  //         const _curntCursor = Math.round(totlrecord / row);
  //         this.tblResponse.First = _curntCursor + 1;
  //       }
  //     } else if (resdata.ResponseCode === "400") {
  //       this.refs.Messchild.showWarn(resdata.ResponseMessage);
  //     } else {
  //       this.refs.Messchild.showError(resdata.ResponseMessage);
  //     }
  //   });
  // }
  GetCompany() {
    this.CompanyMasterService.GetCompanyDDL().then((data) => {
      const resdata = data.data;
      if (
        resdata !== null &&
        resdata.ResponseData !== null &&
        resdata.ResponseData.length > 0
      ) {
        this.setState({
          companyDDL: resdata.ResponseData,
        });

        if (this.ApiService.getIsAdmin() == "false") {
          const CompanyObj = this.state.companyDDL.filter(
            (x) => x.CompId == this.ApiService.getCompanyId()
          );
          this.setState({
            CompanyId: CompanyObj[0],
            compDisabled: true,
          });
        }
      }
    });
  }
  setOnEditDDL(rowData) {
    this.SetState(rowData.ship_Country, "ship_Country");
    this.SetState(rowData.bill_Country, "bill_Country");
    this.SetCity(rowData.ship_State, "ship_State");
    this.SetCity(rowData.bill_State, "bill_State");
  }

  // Search Filter
  searchFilter(e) {
    this.setState({
      loading: true,
    });
    this.tblResponse.SearchValue = e.target.value;
    this.GetUsers(this.tblResponse);
  }

  callbackFunction = (childData) => {
    this.setState({ message: childData });
  };

  // disabled check box
  activeTemplate(rowData, column) {
    return <input type="checkbox" disabled checked={rowData.IsActive} />;
  }

  // sort page
  onSort(event) {
    this.setState({
      sortOrder: this.state.sortOrder === 1 ? 2 : 1,
      sortIcon: !this.state.sortIcon,
    });
    const sortField =
      event.sortField == "CreatedDt" ? "CreatedDt" : event.sortField;
    this.tblResponse.SortColumn = sortField ? sortField : "";
    this.tblResponse.SortOrder = this.state.sortOrder === 1 ? "DESC" : "ASC";
    this.GetUsers(this.tblResponse);
    const clickData = document.getElementsByClassName(
      "p-sortable-column-icon pi pi-fw pi-sort"
    );
    console.log(event);
  }

  // edit form
  actionTemplate(rowData, column) {
    const editMode = (e) => {
      
      this.setState({
        buttonLabel: "Update",
        formHeading: "Update Salesperson",
        visible: true,
        ReferenceID: rowData.ReferenceID,
        userId: rowData.userId,
        UserName: rowData.Username,
        FirstName: rowData.FirstName,
        LastName: rowData.LastName,
        Email: rowData.Email,
        MobileNo: rowData.MobileNo,
        Password: rowData.Password,
        ConfirmPassword: rowData.Password,
        EmpId: rowData.EmpId,
        IsActive: rowData.IsActive,
       
      });

      const CompanyObj = this.state.companyDDL.filter(
        (x) => x.CompId == rowData.CompanyId
      );

   
      this.setState({
       
        CompanyId: CompanyObj[0],
      });
    };

  

    return (
      <span>
        <Button
          type="button"
          icon="pi pi-pencil"
          onClick={editMode}
          className="p-button-warning"
          style={{ marginRight: "3px" }}
        ></Button>
        {/* <Button
          type="button"
          icon="pi pi-trash"
          onClick={deleteFunc}
          className="p-button-danger"
        ></Button> */}
      </span>
    );
  }

  // create html part
  //resolved KREP-36
  render() {
    const items = [{ label: "Masters" }, { label: "Saleperson Master" }];
    const home = { icon: "pi pi-home", url: "/dashboard" };
    let header = (
      <div style={{ textAlign: "right" }}>
        <i className="pi pi-search" style={{ margin: "4px 4px 0 0" }}></i>
        <InputText
          type="search"
          onInput={this.searchFilter}
          placeholder="Global Search"
          size="30"
        />
        {/* <Button label="Add Dealer" style={{ marginLeft: '8px' }} icon="pi pi-plus-circle" onClick={this.openDialog} /> */}
      </div>
    );
    return (
      <>
        <MessageComponent ref="Messchild" />
        {<Loaders loader={this.state.updateLoader} />}
        <ConfirmationModal parentCallback={this.callbackFunction} ref="child" />
        <Card>
          <div className="row mb-3">
            <div className="col-md-6 heading">
              <h1>Salesperson Master</h1>
            </div>
            <div className="col-md-6 custom-breadcrumb">
              <BreadCrumb model={items} home={home} />
            </div>
          </div>

          <div style={{ textAlign: "right", marginBottom: "10px" }}>
            <Button
              label="Add Salesperson"
              icon="pi pi-plus-circle"
              onClick={this.openDialog}
            />
            <CSVLink
              data={this.state.exportData}
              headers={this.state.heads}
              filename={"Salesperson Data.csv"}
              target="_blank"
            >
              <button
                disabled={this.state.exportData.length == 0 ? true : false}
                className="btn btn-success p-2 ml-2 buttone1"
              >
                <i className="pi pi-file-excel mr-2"></i>
                Export
              </button>
            </CSVLink>
          </div>

          <DataTable
            responsive={true}
            header={header}
            globalFilter={this.state.globalFilter}
            emptyMessage={this.state.emptyMessage}
            value={this.state.sales}
            sortMode="multiple"
            paginator={true}
            rows={this.state.rows}
            rowsPerPageOptions={[5, 10, 20]}
            currentPageReportTemplate="Showing {first} to {last} of {totalRecords}"
            paginatorTemplate="CurrentPageReport FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink RowsPerPageDropdown"
            totalRecords={this.state.totalRecords}
            lazy={true}
            first={this.state.first}
            onPage={this.onPage}
            onSort={this.onSort}
            className="tblAuto"
          >
            <Column field="CompanyName" header="Company" sortable={true} />
            {/* <Column field="Username" header="User Code" sortable={true} /> */}
            <Column field={"FirstName"} header="Salesperson Name" sortable="custom" />
            <Column field={"EmpId"} header="Emp Id" sortable="custom" />
            {/* <Column field="LastName" header="Last Name" sortable={true} /> */}
            {/* <Column field="Email" header="Email" sortable={true} /> */}
            <Column field="MobileNo" header="Mobile No." sortable={true} />
            <Column field="CreatedDate" header="Created Date" sortable={true} />
            <Column body={this.activeTemplate} header="Status" />
            <Column body={this.actionTemplate} header="Action" />
          </DataTable>
        </Card>

        <Dialog
          className="popup-modal"
          style={{ width: "50%" }}
          header={this.state.formHeading}
          visible={this.state.visible}
          modal={true}
          onHide={this.hideFunc}
        >
          <form
            onSubmit={this.submitForm}
            className="form-padding"
            style={{ overflowY: "auto", overflowX: "hidden", height: "450px" }}
          >
            <div className="row">
              <div className="col-md-12">
                <label>
                  Company<span>*</span>:
                </label>
                <Dropdown
                  disabled={this.state.compDisabled}
                  optionLabel="CompanyName"
                  onChange={(e) => {
                    this.handleChange(e);
                  }}
                  value={this.state.CompanyId}
                  className="form-control"
                  placeholder="Select Company"
                  name="CompanyId"
                  options={this.state.companyDDL}
                />
                {this.state.formError.CompanyId !== null ? (
                  <div className="error">{this.state.formError.CompanyId}</div>
                ) : null}
              </div>
            </div>

            <div className="row">
              <div className="col-md-12">
                                <label>Emp. Id<span></span>:</label>
                                <input type="text" value={this.state.EmpId} onChange={this.handleChange} className="form-control" name="EmpId"  />
                                {this.state.formError.EmpId !== '' ?
                                    <div className='error'>{this.state.formError.EmpId}</div> : ''}
                            </div>
              <div className="col-md-12">
                <label>
                  Name<span>*</span>:
                </label>
                <input
                  type="text"
                  value={this.state.FirstName}
                  onChange={this.handleChange}
                  className="form-control"
                  name="FirstName"
                />
                {this.state.formError.FirstName !== "" ? (
                  <div className="error">{this.state.formError.FirstName}</div>
                ) : (
                  ""
                )}
              </div>
              {/* <div className="col-md-6">
                                <label>Last Name<span>*</span>:</label>
                                <input type="text" value={this.state.LastName} onChange={this.handleChange} className="form-control" name="LastName" />
                                {this.state.formError.LastName !== '' ?
                                    <div className='error'>{this.state.formError.LastName}</div> : ''}
                            </div> */}
              <div className="col-md-12">
                <label>Email<span>*</span>:</label>
                <input type="text" value={this.state.Email} onChange={this.handleChange} className="form-control" name="Email" />
                {this.state.formError.Email !== null ?
                  <div className='error'>{this.state.formError.Email}</div> : null}
              </div>
              <div className="col-md-12">
                <label>
                  Mobile Number<span>*</span>:
                </label>
                <input
                  type="text"
                  maxLength={10}
                  value={this.state.MobileNo}
                  onChange={this.handleChange}
                  className="form-control"
                  name="MobileNo"
                />
                {this.state.formError.MobileNo !== null ? (
                  <div className="error">{this.state.formError.MobileNo}</div>
                ) : null}
              </div>
              <div className="col-md-12">
                <label>
                  Password<span>*</span>:
                </label>
                <input
                  type="password"
                  autoComplete="off"
                  value={this.state.Password}
                  onChange={this.handleChange}
                  className="form-control"
                  name="Password"
                  noValidate
                />
                {this.state.formError.Password !== null ? (
                  <div className="error">{this.state.formError.Password}</div>
                ) : null}
              </div>
              <div className="col-md-12">
                <label>
                  Confirm Password<span>*</span>:
                </label>
                <input
                  type="password"
                  autoComplete="off"
                  value={this.state.ConfirmPassword}
                  onChange={this.handleChange}
                  className="form-control"
                  name="ConfirmPassword"
                />
                {this.state.formError.ConfirmPassword !== null ? (
                  <div className="error">
                    {this.state.formError.ConfirmPassword}
                  </div>
                ) : null}
              </div>
            
             
              
             
                {/* <div>
                                    <label>Address 2<span>*</span>:</label>
                                    <input name="bill_Address2" type="text" value={this.state.bill_Address2} onChange={this.handleChange} className="form-control" />
                                    {this.state.formError.bill_Address2 !== null ?
                                        <div className='error'>{this.state.formError.bill_Address2}</div> : null}
                                </div> */}
               
              </div>
             
                {/* <div>
                                    <label>Address 2<span>*</span>:</label>
                                    <input name="ship_Address2" type="text" className="form-control" onChange={this.handleChange} value={this.state.ship_Address2} />
                                    {this.state.formError.ship_Address2 !== null ?
                                        <div className='error'>{this.state.formError.ship_Address2}</div> : null}
                                </div> */}
               
              
              <div className="col-md-12">
                <label>IsActive :</label>
                <input
                  type="checkbox"
                  checked={this.state.IsActive}
                  className="ml-2"
                  name="IsActive"
                  onChange={this.handleChange}
                />
              </div>
              <div className="col-md-12 mt-3 text-center">
                <button type="submit" className="btn btn-primary">
                  {this.state.buttonLabel}
                </button>
              </div>
              <div className="col-md-12 text-left">
                <label>
                  Note: Fields marked as (
                  <span style={{ color: "red" }}>*</span>) are required
                </label>
              </div>
           
          </form>
        </Dialog>
      </>
    );
  }
}
