import React, { Component } from "react";
import Sidebar from "./Sidebar";
import Footer from "./Footer";
import Dashboard from "./../views/Dashboard";
import AccessRight from "./../views/master/AccessRight";
import ProductMaster from "./../views/master/ProductMaster";
import UserMaster from "./../views/master/UserMaster";
import CartonMaster from "./../views/master/CartonMaster";
import CompanyMaster from "./../views/master/CompanyMaster";
import DealerMaster from "./../views/master/DealerMaster";
import ListMaster from "./../views/master/ListMaster";
import PriceMaster from "./../views/master/PriceMaster";
import DistributorMaster from "./../views/master/DistributorMaster";
import DeMapping from "./../views/transaction/DeMapping";
import Mapping from "./../views/transaction/Mapping";
import Dispatch from "./../views/transaction/Dispatch";
import SecondaryPacking from "./../views/transaction/SecondaryPacking";
import TertiaryPacking from "./../views/transaction/TertiaryPacking";
import NumberIssued from "./../views/transaction/NumberIssued";
import StockManagement from "./../views/transaction/StockManagement";
import ProductDamage from "./../views/transaction/ProductDamage";
import StaticMapping from "./../views/transaction/StaticMapping";
import CartonSlip from "./../views/transaction/CartonSlip";
import LoyaltyRedeemRequests from "./../views/transaction/LoyaltyRedeemRequests";
import ProductConfiguration from "./../views/master/ProductConfiguration";
import MappingReport from "./../views/reports/MappingReport";
import VerificationReport from "./../views/reports/VerificationReport";
import FeedbackReport from "./../views/reports/FeedbackReport";
import SAPDataReport from "./../views/reports/SAPDataReport";
import DispatchReport from "./../views/reports/DispatchReport";
import PrimaryPackingReport from "./../views/reports/PrimaryPackingReport";
import SecondaryPackingReport from "./../views/reports/SecondaryPackingReport";
import TertiaryPackingReport from "./../views/reports/TertiaryPackingReport";
import ItemLedger from "./../views/reports/ItemLedger";
import SchemeReport from "./../views/reports/SchemeReport";
import RedemptionReport from "./../views/reports/RedemptionReport";
import MissingCartonReport from "./../views/reports/MissingCartonReport";
import DispatchReceiveRpt from "./../views/reports/DispatchReceiveRpt";
import PickLuckyUsers from "../views/reports/PickLuckyUsers";
import FractionalCartonRpt from "./../views/reports/FractionalCartonsRpt";
import ReturnReport from "./../views/reports/ReturnReport";
import Profile from "./../views/Profile";
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import Logo from "./../assets/images/logo.png";
import Avtar from "./../assets/images/avatar.jpg";
import "./../App.css";
import Message from "./Message";
import SchemeMaster from "../views/master/SchemeMaster";
import SchemeConfiguration from "../views/transaction/SchemeConfiguration";
import Category from "../views/master/Category";
import UserService from "../services/UserService";
import NumberGeneration from "../views/transaction/NumberGeneration";
import DispatchReceive from "../views/transaction/DispatchReceive";
import WareHouseMaster from "../views/master/WareHouseMaster";
import ItemWiseStockDetail from "../views/reports/ItemWiseStockDetail";
import DamageRpt from "../views/reports/DamageRpt";
import DeMappingReport from "../views/reports/DeMappingReport";
import damageCarton from "../views/transaction/damageCarton";
import QrCodeReport from "../views/reports/QrCodeReport";
import ChangePassword from "../views/ChangePassword";
import FactoryMaster from "../views/master/FactoryMaster";
import AdminNumberGenerated from "../views/transaction/AdminNumberGenerated";
import ReturnCarton from "../views/transaction/ReturnCarton";
import Replace from "../views/transaction/Replace";
import DispatchLoyaltyReport from "../views/reports/DealerLoyaltyReport";
import ItemStockDetail from "../views/reports/ItemStockDetail";
import SalePersonMaster from "../views/master/SalePersonMaster";
// use for layout
export default class Layout extends Component {
  constructor(props) {
    super(props);
    this.state = {
      UserName: "",
      toggle: false,
      value: null,
    };

    this.UserService = new UserService();
  }
  //trigger api function on page call
  componentDidMount() {
    setTimeout(() => {
      this.CheckIsResetPassword();
      this.getUser();
    }, 1000);
  }
  //to get user data
  getUser() {
    this.UserService.getUser().then((data) => {
      const resdata = data.data;

      if (resdata.ResponseCode === "200") {
        const usr = resdata.ResponseData;
        this.setState({
          UserName: usr.Username,
          UserId: usr.UserId,
        });
        sessionStorage.setItem("wid", usr.WareHouseId);
        sessionStorage.setItem("uid", usr.UserId);
      }
    });
  }
  // Check Reset Password
  CheckIsResetPassword() {
    this.UserService.getUser().then((res) => {
      const curl = window.location.pathname;

      const resdata = res.data;
      //if ResponData not null
      if (
        resdata !== null &&
        resdata.ResponseCode === "200" &&
        resdata.ResponseData != null &&
        resdata.ResponseData.IsResetPassword === true
      ) {
        if (!curl.includes("/profile")) {
          window.location = "/profile";
        }
      } else {
      }
    });
  }

  handleChange = (value) => {
    this.setState({ value });
  };

  // Render HTML
  //updated  Render HTML to resolve defect KREP-12 on [08-07-20]
  render() {
    return (
      <>
        <div className={this.state.toggle ? "row1 open" : "row1"}>
          <div className="col-md-12">
            <div className="header-top">
              <div className="full-width">
                <div className="logo-section">
                  <img src={Logo} alt="MSRM Organics" />
                </div>
                <div className="right-section">
                  <div className="user-area dropdown">
                    <img
                      alt="User Avatar"
                      className="user-avatar rounded-circle"
                      src={Avtar}
                    />
                    <span className="dropdown-toggle active">
                      Welcome, {this.state.UserName}
                    </span>
                    <div className="user-menu">
                      <div className="nav-link">
                        <a href="/profile">
                          <i className="fa fa-address-card"></i>Profile
                        </a>
                      </div>
                      <div className="nav-link">
                        <a href="/change-password">
                          <i className="fa fa-key"></i>Change Password
                        </a>
                      </div>
                      <div
                        className="nav-link"
                        onClick={(e) => {
                          sessionStorage.removeItem("LoggedInUser");
                          window.location = "/login";
                        }}
                      >
                        <i className="fa fa-power-off"></i>Logout
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="col-md-12 menu-bar">
            <Sidebar />
          </div>
          <div className="full-width">
            <Router>
              <Switch>
                <Route exact path="/" component={Dashboard} />
                <Route exact path="/dashboard/" component={Dashboard} />
                <Route path="/profile/" component={Profile} />
                <Route path="/change-password/" component={ChangePassword} />
                <Route path="/masters/access-right/" component={AccessRight} />
                <Route
                  path="/masters/product-master/"
                  component={ProductMaster}
                />
                <Route
                  path="/masters/scheme-master/"
                  component={SchemeMaster}
                />
                <Route
                  path="/masters/product-configuration"
                  component={ProductConfiguration}
                />
                <Route path="/masters/user-master/" component={UserMaster} />
                <Route
                  path="/masters/warehouse-master/"
                  component={WareHouseMaster}
                />
                <Route
                  path="/masters/factory-master/"
                  component={FactoryMaster}
                />
                <Route
                  path="/masters/carton-master/"
                  component={CartonMaster}
                />
                <Route
                  path="/masters/distributor-master/"
                  component={DistributorMaster}
                />
                <Route path="/masters/list-master/" component={ListMaster} />
                <Route path="/masters/price-master/" component={PriceMaster} />
                <Route
                  path="/masters/dealer-master/"
                  component={DealerMaster}
                />
                 <Route
                  path="/masters/saleperson-master/"
                  component={SalePersonMaster}
                />
                <Route
                  path="/masters/company-master/"
                  component={CompanyMaster}
                />
                <Route path="/masters/category/" component={Category} />
                <Route path="/transaction/de-mapping/" component={DeMapping} />
                <Route path="/transaction/mapping/" component={Mapping} />
                <Route
                  path="/transaction/number-issued/"
                  component={NumberIssued}
                />
                <Route
                  path="/transaction/secondary-packing/"
                  component={SecondaryPacking}
                />
                <Route
                  path="/transaction/tertiary-packing/"
                  component={TertiaryPacking}
                />
                <Route
                  path="/transaction/product-damage/"
                  component={ProductDamage}
                />
                <Route path="/transaction/dispatch/" component={Dispatch} />
                <Route
                  path="/transaction/scheme-configuration/"
                  component={SchemeConfiguration}
                />
                <Route
                  path="/transaction/stock-management/"
                  component={StockManagement}
                />
                <Route
                  path="/transaction/number-generation/"
                  component={NumberGeneration}
                />
                <Route
                  path="/transaction/admin-number-download/"
                  component={AdminNumberGenerated}
                />
                <Route
                  path="/transaction/dispatch-receive/"
                  component={DispatchReceive}
                />
                <Route
                  path="/transaction/map-static-number/"
                  component={StaticMapping}
                />
                <Route
                  path="/transaction/Damage-carton/"
                  component={damageCarton}
                />
                <Route
                  path="/transaction/Carton-Slip/"
                  component={CartonSlip}
                />
                <Route
                  path="/transaction/redemption-requests/"
                  component={LoyaltyRedeemRequests}
                />
                <Route
                  path="/transaction/carton-return/"
                  component={ReturnCarton}
                />
                <Route
                  path="/reports/mapping-report/"
                  component={MappingReport}
                />
                <Route
                  path="/reports/Demapping-report/"
                  component={DeMappingReport}
                />
                <Route
                  path="/reports/verification-report/"
                  component={VerificationReport}
                />
                <Route
                  path="/reports/return-report/"
                  component={ReturnReport}
                />
                <Route
                  path="/reports/feedback-report/"
                  component={FeedbackReport}
                />
                <Route
                  path="/reports/sapdata-report/"
                  component={SAPDataReport}
                />
                <Route
                  path="/reports/dispatch-report/"
                  component={DispatchReport}
                />
                <Route
                  path="/reports/dispatch-receive-report/"
                  component={DispatchReceiveRpt}
                />
                <Route
                  path="/reports/primary-packing-report/"
                  component={PrimaryPackingReport}
                />
                <Route
                  path="/reports/secondary-packing-report/"
                  component={SecondaryPackingReport}
                />
                <Route
                  path="/reports/tertiary-packing-report/"
                  component={TertiaryPackingReport}
                />
                <Route
                  path="/reports/redemption-report/"
                  component={RedemptionReport}
                />
                <Route
                  path="/reports/scheme-report/"
                  component={SchemeReport}
                />
                <Route path="/reports/item-ledger/" component={ItemLedger} />
                <Route
                  path="/reports/stock-report/"
                  component={ItemWiseStockDetail}
                />
                <Route path="/reports/Damage-report/" component={DamageRpt} />
                <Route
                  path="/reports/qr-code-tracking-report"
                  component={QrCodeReport}
                />
                <Route
                  path="/reports/missing-carton-report/"
                  component={MissingCartonReport}
                />
                <Route
                  path="/reports/fractional-carton-report/"
                  component={FractionalCartonRpt}
                />
                <Route
                  path="/reports/Dispatch-Loyalty-report/"
                  component={DispatchLoyaltyReport}
                />
                <Route
                  path="/reports/lucky-users/"
                  component={PickLuckyUsers}
                />
                <Route path="/reports/new-Stock/" component={ItemStockDetail} />
                <Route path="/transaction/replace" component={Replace} />

                <Route path="*" component={Dashboard} />
              </Switch>
            </Router>
          </div>

          <div className="Message">
            <Message />
          </div>
        </div>

        <Footer />
      </>
    );
  }
}
