import instance from "./DefaultApi";

export default class DispatchService {
  ControllerName = "Dispatch";

  // Save Dispatch
  Save(formData) {
    return instance
      .post(
        `${process.env.REACT_APP_API_URL}${this.ControllerName}/save`,
        formData
      )
      .then((res) => res);
  }
  ReturnCarton(formData) {
    return instance
      .post(
        `${process.env.REACT_APP_API_URL}${this.ControllerName}/ReturnCarton`,
        formData
      )
      .then((res) => res);
  }

  // Save Dispatch Carton Receive
  DispatchCartonReceive(formData) {
    return instance
      .post(
        `${process.env.REACT_APP_API_URL}${this.ControllerName}/DispatchCartonReceive`,
        formData
      )
      .then((res) => res);
  }

  // Save Dispatch Receive
  SaveDispatchReceive(formData) {
    return instance
      .post(
        `${process.env.REACT_APP_API_URL}${this.ControllerName}/SaveDispatchReceive`,
        formData
      )
      .then((res) => res);
  }

  // Get Dispatch Receive Carton
  GetDispatchReceiveCarton(formData) {
    return instance
      .post(
        `${process.env.REACT_APP_API_URL}${this.ControllerName}/GetDispatchReceiveCarton`,
        formData
      )
      .then((res) => res);
  }
  GetReturnReport(tableRequest) {
    debugger
    return instance.post(`${process.env.REACT_APP_API_URL}${this.ControllerName}/GetReturnReport`, tableRequest).then(res => res)
}
  // get Dispatch Receive
  GetDispatchReceive(tbl) {
    return instance
      .post(
        `${process.env.REACT_APP_API_URL}${this.ControllerName}/GetDispatchReceive`,
        tbl
      )
      .then((res) => res);
  }

  // get Dispatch
  GetDispatch(tbl) {
    return instance
      .post(
        `${process.env.REACT_APP_API_URL}${this.ControllerName}/GetDispatch`,
        tbl
      )
      .then((res) => res);
  }

  // get Dispatch
  GetDispatch(tbl) {
    return instance
      .post(
        `${process.env.REACT_APP_API_URL}${this.ControllerName}/GetDispatch`,
        tbl
      )
      .then((res) => res);
  }
  // Transport mode ddl
  GetModeDDL() {
    ;
    return instance
      .get(`${process.env.REACT_APP_API_URL}${this.ControllerName}/GetModeDDL`)
      .then((res) => res);
  }

  // scan get tertiary packing
  GetTertiaryPackingScan(d, checkItemDate, scanlist) {
    return instance
      .get(
        `${process.env.REACT_APP_API_URL}${this.ControllerName}/GetTertiaryPackingScan?sid=` +
          d +
          `&&checkItemDate=` +
          checkItemDate,
        scanlist
      )
      .then((res) => res);
  }
  GetReturnInfo(cartonID, isTransit, wareHouseId) {
    return instance.post(
      `${process.env.REACT_APP_API_URL}${this.ControllerName}/GetPackingForReturn`,
      {
        cartonID: cartonID,
        isTransit: isTransit,
        wareHouseId: wareHouseId
      }
    ).then((res) => res);
  }
  // get all data
  // scan get tertiary packing
  GetJourney(d) {
    return instance
      .get(
        `${process.env.REACT_APP_API_URL}${this.ControllerName}/GetJourney?qr=` +
          d,
        null
      )
      .then((res) => res);
  }
  GetScanInfo(d) {
    return instance
      .get(
        `${process.env.REACT_APP_API_URL}${this.ControllerName}/GetScanInfo?qr=` +
          d,
        null
      )
      .then((res) => res);
  }
}
